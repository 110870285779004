import { transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AnimationControllerService, GeneralLoaderService, StaticUtilitiesService, TokenManagmentService, VariablesDesignSystemService, slideNavigationFunctions, slideNavigationTypes } from '@quasar-dynamics/ngx-qd-design-system';
import { LoginService } from './Services/Api/Login.service';
import { environment } from '../environments/environment';
import { Subject, filter, map, takeUntil } from 'rxjs';
import { NnLoaderComponent } from './Shared/Components/nn-loader/nn-loader.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [
    trigger('routerTransition', [
      transition(
        '* => slideToLeft',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)
      ),
      transition(
        '* => slideToRight',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)
      ),
      transition(
        '* => slideToTop',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)
      ),
      transition(
        '* => slideToBottom',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom
        )
      ),
      transition(
        '* => slideToLeftDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)
      ),
      transition(
        '* => slideToRightDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)
      ),
      transition(
        '* => slideToTopDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)
      ),
      transition(
        '* => slideToBottomDuplicate',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom
        )
      ),
    ]),
  ],

})
export class AppComponent implements OnInit {
  title = 'Nucleo nova';

  constructor(    private router: Router,
    private titleSE: Title,
    public animService: AnimationControllerService,
    private loginSE: LoginService,
    private generalLoaderSE: GeneralLoaderService,

){}

ngOnInit(){
  VariablesDesignSystemService.setIsRelease(environment.production);
  VariablesDesignSystemService.setUrlApi(environment.urlApi);
  this.generalLoaderSE.setLoaderComponent(NnLoaderComponent);
  // this.refreshTokenHandler();
  this.routerHandler();


}

// refreshTokenHandler() {
//   const _unsub = new Subject<void>();
//   TokenManagmentService.listenerTokenLost()
//     .pipe(takeUntil(_unsub))
//     .subscribe((res) => {
//       this.loginSE.isLogged()
//         ? StaticUtilitiesService.showError(
//             'Ha habido un problema, intentalo más tarde'
//           )
//         : null;

//       this.loginSE.logOut();
//     });

//   TokenManagmentService.listenerStartRefreshToken()
//     .pipe(takeUntil(_unsub))
//     .subscribe((res) => {
//       if (!location.pathname.includes('login')) {
//         this.loginSE.refreshToken();
//       }
//     });
// }

routerHandler() {
  this.animService.setCurrentAnimation(slideNavigationTypes.slideToTop);
  this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
        }
        return routeTitle;
      })
    )
    .subscribe((title: string) => {
      if (title) {
        this.titleSE.setTitle(`Nucleo nova - ${title}`);
      }
    });
}



}
