import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  FileInputDirective,
  NgxQdDesignSystemModule,
} from '@quasar-dynamics/ngx-qd-design-system';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './Shared/shared.module';
import {
  CommonModule,
  CurrencyPipe,
  registerLocaleData,
} from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from './Shared/Adapters/CustomDateAdapter';
import localEs from '@angular/common/locales/es';
import { AddDocumentPopupComponent } from './Popups/Add-Document-Popup/Add-Document-Popup.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AssertDeletePopupComponent } from './Popups/Assert-Delete-Popup/Assert-Delete-Popup.component';
import { ViewObservationsPopupComponent } from './Popups/View-Observations-Popup/View-Observations-Popup.component';
import { FormsModule } from '@angular/forms';
import { NnLoaderComponent } from './Shared/Components/nn-loader/nn-loader.component';
import { MoveToPopupComponent } from './Popups/Move-To-Popup/Move-To-Popup.component';
import { AddComponentsBulkPopupComponent } from './Popups/Add-Components-Bulk-Popup/Add-Components-Bulk-Popup.component';
import { AddClientPopupComponent } from './Popups/Add-Client-Popup/Add-Client-Popup.component';
import { EditOfferPopupComponent } from './Popups/Edit-Offer-Popup/Edit-Offer-Popup.component';
import { SignatureManagementPopupComponent } from './Popups/Signature-Management-Popup/Signature-Management-Popup.component';
import { ImportExportOfferPopupComponent } from './Popups/Import-Export-Offer-Popup/Import-Export-Offer-Popup.component';
import { MatRadioModule } from '@angular/material/radio';
import { AssertCopyPopupComponent } from './Popups/Assert-Copy-Popup/Assert-Copy-Popup.component';

registerLocaleData(localEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    AddDocumentPopupComponent,
    AssertDeletePopupComponent,
    AssertCopyPopupComponent,
    ViewObservationsPopupComponent,
    MoveToPopupComponent,
    AddComponentsBulkPopupComponent,
    AddClientPopupComponent,
    EditOfferPopupComponent,
    SignatureManagementPopupComponent,
    ImportExportOfferPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxQdDesignSystemModule,
    BrowserAnimationsModule,
    NgSelectModule,
    HttpClientModule,
    SharedModule,
    CommonModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    MatRadioModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
