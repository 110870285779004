import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationControllerService, DefaultBaseService, TokenManagmentService, iResultHttp, iStaticUtilities } from '@quasar-dynamics/ngx-qd-design-system';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends DefaultBaseService{
  res: iResultHttp = new iResultHttp();

  constructor(
    private http: HttpClient,
    private router: Router,
    private animationSE: AnimationControllerService
  ) {
    super();
  }
logOut() {
  localStorage.clear();
  sessionStorage.clear();
}
// isLogged() {
//   if (this.getToken() && this.getRefreshToken()) {
//     return true;
//   }
//   return false;
// }

refreshToken() {
  this.http
    .post(environment.urlApi + '/api/token/refresh', {
      refresh_token: localStorage.getItem('refreshToken'),
    })
    .subscribe({
      next: (data) => {
        let normalizedTokens = iStaticUtilities.normalizeNames(data);
        this.setToken(normalizedTokens['token']);
        this.setRefreshToken(normalizedTokens['refreshToken']);
        TokenManagmentService.completeRefreshToken();
      },
      error: (error) => {
        TokenManagmentService.tokenLost();
      },
    });
}


}
